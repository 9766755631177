import { getDocumentDownloadUrl } from './api-helper';

const pdfUrl = `${process.env.REACT_APP_GSP_DOCUMENTS_API}/documents/statics`;

export const getStaticPdf = async (staticPdfCode) => {
  try {
    return await getDocumentDownloadUrl(`${pdfUrl}?staticDocumentCode[]=${staticPdfCode}`);
  } catch (e) {
    console.error(`${staticPdfCode} pdf is unavailable`, e);
    return undefined;
  }
};
